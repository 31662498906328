import { Div } from "@max/common-ui";
import { Footer, ShareIcons } from "@max/common-ui";
import { AppContainer, AppLayout } from "@max/common-ui";
import { useEffect } from "react";
import styled from "styled-components";
import { theme } from "theme";
import Header, {
  ContentColumns,
  HeaderText,
  HeaderTextContainer,
} from "../../common/Header";
import Metatags from "./Metatags";
import ShoutoutForm from "components/common/ShoutoutForm";
import { Section } from "@max/common-ui";
import { useMediaQuery } from "@max/common-ui";
import { usePaymentContext } from "components/PaymentContextProvider";
import { shoutoutI } from "firebase-tools";
import { generatePath, useHistory, useRouteMatch } from "react-router";
import { ROUTES } from "..";
import { useAppContext } from "AppContextProvider";
import { Videojs } from "@max/common-ui";
import { getDisplayPrice } from "components/utils";

const whatToExpectList = (responseTimeDays) => [
  `Your request will be reviewed within ${responseTimeDays} day${
    responseTimeDays !== 1 ? "s" : ""
  }`,
  "Your receipt and order updates will be sent to the email provided on the next step",
  "When your request is completed, we’ll email you a link to view, share, or download your personalized video message",
];

const Divider = styled(Div)`
  height: 4px;
  width: 100%;
  background: ${(props) => props.theme.colors.locationTextDarkMode};
`;

const PriceText = styled(Div)`
  font-weight: 900;
  font-size: 25px;
`;

const PerMessageText = styled(Div)`
  font-weight: 600;
  font-size: 15px;
`;

const FormWrapper = styled(Div)`
  margin-top: -80px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
  }
`;

const CampaignEndedText = styled(Div)`
  text-align: center;
  font-weight: 300;
  @media all and ${(props) => props.theme.media.mobile} {
    padding: 0 50px;
    font-weight: 300;
  }
`;

const Info = styled(Div)`
  margin-top: 40px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
  }
`;

const VideoWrapper = styled(Div)`
  border-radius: 9px;
  overflow: hidden;
  position: relative;
  margin-top: -121px;
  margin-bottom: 40px;
`;

const ShoutoutOrderForm = () => {
  const { isLoadingShoutout } = usePaymentContext();
  return <>{!isLoadingShoutout && <ShoutoutOrderFormWithData />}</>;
};

const ShoutoutOrderFormWithData = () => {
  const { shoutout, shoutoutId, setRequestId } = usePaymentContext();
  const { setAppIsLoading } = useAppContext();
  const {
    artistGroupName,
    assets: {
      headerLarge: { path: backgroundImageUrl },
      headerSmall: { path: mobileBackgroundImageUrl },
      video: { path: videoUrl },
    },
    rules,
    responseTimeDays,
    unitPrice,
    hideSetliveHeaderLogo,
  } = shoutout;
  const history = useHistory();
  const isMobile = useMediaQuery(theme.media.mobile);
  const pricePerMessage = getDisplayPrice(unitPrice);
  const match = useRouteMatch();
  const baseUrl = match.path;
  const isCampaignEnded = !!shoutout?.endedAt;

  useEffect(() => {
    if (isMobile) document.body.style.backgroundColor = theme.colors.white;
    else document.body.style.backgroundColor = theme.colors.background;
  }, [isMobile]);

  const onSubmitForm = async (values) => {
    const form = shoutoutI.firestore.shoutouts.RequestForm.load(values);
    setAppIsLoading(true);
    const res = await shoutoutI.functions().shoutouts.createRequest({
      shoutoutId,
      form: form,
    });
    const { result, error } = res;
    setAppIsLoading(false);
    if (!error) {
      setRequestId(result.requestId);
      history.push(generatePath(`${baseUrl}${ROUTES.PAYMENT}`, { shoutoutId }));
    }
  };

  const headerUI = (
    <HeaderTextContainer displayVideo={!!videoUrl}>
      <HeaderText text={artistGroupName} />
      <Div dflex mt_40>
        {isCampaignEnded ? (
          <Div mt_10 />
        ) : (
          <>
            <PriceText>{pricePerMessage}</PriceText>
            <PerMessageText ml_5 mt_10>
              /per message
            </PerMessageText>
          </>
        )}
      </Div>
    </HeaderTextContainer>
  );

  const Bullet = ({ text }) => {
    return (
      <Div dflex>
        •
        <Div ml_17 lh_27 c_inputPlaceholderText>
          {text}
        </Div>
      </Div>
    );
  };

  const videoUI = videoUrl && (
    <VideoWrapper w100 forDesktop>
      <Videojs
        headerSkin={true}
        src={videoUrl}
        width="100%"
        autoPlay={true}
        autoHideControls={false}
        muted={true}
        withObjectFitCover={true}
        height="500px"
      />
    </VideoWrapper>
  );
  const infoColumnUI = (
    <Info>
      {videoUI}
      <Divider forMobile mb_27 />
      <Div forDesktop></Div>
      <Div mcontainer>
        <Div bold fs_17 mb_7>
          What to Expect
        </Div>
        {whatToExpectList(responseTimeDays).map((text, index) => (
          <div key={index}>
            <Bullet text={text} />
          </div>
        ))}
        <Bullet text={rules} />
        <Divider forDesktop mt_38 />
        <Div forDesktop>
          <ShareIcons shareUrl={window.location.href} />
        </Div>
      </Div>
    </Info>
  );

  const campaignEndedUI = (
    <CampaignEndedText mt_50 mb_50 centered fs_22 semiBold>
      New ShoutOut requests for {artistGroupName} are not currently being
      accepted here
    </CampaignEndedText>
  );

  return (
    <AppLayout>
      <Metatags />
      <Header
        isDisabled={isCampaignEnded}
        withLogo={!hideSetliveHeaderLogo}
        videoUrl={videoUrl}
        shareUrl={window.location.href}
        backgroundImageUrl={
          isMobile ? mobileBackgroundImageUrl : backgroundImageUrl
        }
      >
        {headerUI}
      </Header>
      <AppContainer>
        <ContentColumns>
          <FormWrapper mcontainer positionRelative>
            <Section>
              {!isCampaignEnded ? (
                <ShoutoutForm shoutout={shoutout} onSubmit={onSubmitForm} />
              ) : (
                campaignEndedUI
              )}
            </Section>
          </FormWrapper>
          {!isCampaignEnded && infoColumnUI}
        </ContentColumns>
      </AppContainer>
      <Footer
        background={isMobile ? theme.colors.white : theme.colors.background}
      />
    </AppLayout>
  );
};

export default ShoutoutOrderForm;
